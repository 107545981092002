<template>
  <div>
      <Player :options="videoOptions"></Player>
  </div>
</template>

<script>

import Player from '@/views/apps/proctor/Player'

export default {
  components: {
    Player
  },
  props: {
    
  },
  data: function() {
    return {
      videoOptions: {
        autoplay: true,
        controls: true,
        duration: Infinity,
        sources: [
          {
            src: 'http://homestead.local:8081/hls/live1.m3u8'
          }
        ]
      }
    }
  },
  computed: {
    
  },
  methods: {
  },
  mounted() {
    
  },
  beforeDestroy() {
    
  }
}
</script>

<style>
</style>
